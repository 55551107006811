<template>
    <div>
        <div class="h3 mb-5 text-center">Tokens</div>
        <b-card class="card-large">
            <div class="card-content">
                <b-row>
                    <b-col md="5" class="filters">
                        <div class="caption">
                            Filter by asset
                        </div>
                        <b-form-row>
                            <b-col>
                                <b-input placeholder="Token’s name" v-model="searchToken"></b-input>
                            </b-col>
                            <b-col class="flex-grow-0">
                                <button class="button-flat" v-on:click="filter()">Search</button>
                            </b-col>
                        </b-form-row>
                        <div class="caption mt-3">
                            Total amount of pools: {{ totalAmount }}
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <TokensList
                                :tokens = "getTokensList"
                                scroll>
                        </TokensList>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "Tokens",
        components: {
            TokensList: () => import("@/components/dashboard/TokensList"),
        },
        data: function () {
            return {
                getTokensList: [
                    { symbol: 'ETH', name: 'ETH (Wrapped)', liquidity: '8,000,000', amount_symbol: '$', volume: '8,000,000', transactions: '14,414' },
                    { symbol: 'EOS', name: 'Tether USD', liquidity: '23,000,000',  amount_symbol: '$', volume: '23,000,000', transactions: '1,324' },
                    { symbol: 'ICX', name: 'USD//C', liquidity: '34,000,000',  amount_symbol: '$', volume: '34,000,000', transactions: '223' },
                    { symbol: 'TRX', name: 'Synth sUSD', liquidity: '2,600,000',  amount_symbol: '$', volume: '2,600,000', transactions: '2' },
                    { symbol: 'DAI', name: 'Dai Stablecoin', liquidity: '2,600,000',  amount_symbol: '$', volume: '2,600,000', transactions: '1,253' },
                    { symbol: 'VEF', name: 'PickleToken', liquidity: '23,000,000',  amount_symbol: '$', volume: '23,000,000', transactions: '3,525' },
                ],
                totalAmount: 0,
                searchToken: '',
            }
        },
    }
</script>

<style scoped lang="scss">
</style>
